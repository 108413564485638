<template>
    <div class="vr-panel">
			<div id="pano"></div>
			<div class="panomenu" :class="{'close':closepmenu}">
				<div class="scroll">
					<div class="thumbs">
						<div class="item" v-for="(item, index) in scenelist" :key="index" :class="{'active':panoscene == item.name}"><img :src="item.thumburl" @click="loadScene(item.name)"><span>{{item.title}}</span></div>
					</div>
				</div>
				<a class="btn" href="javascript:;" @click="toggleMenu">
					<i class="iconfont icon-arrow"></i>
				</a>
			</div>
    </div>
</template>
<script>
// import { mapState } from 'vuex'

// var krpano = null;

export default {
    name:'VR',
    data(){
			// const vm = this
			const self = this
        return{
					// krpano: null,
					// scene:'150',
					// panoscene: 'scene_ct',
					scenelist: [],
					closepmenu: false,
					hooks:{
						panoChanged() {
							self.panoChanged()
						},
						sceneChanged(scene){
							console.log(scene)
						},
						clickHotspot(name) {
							self.clickHotspot(name)
						}
					}
				}
		},
		props: {
			scene: {
				type: String,
				default: '150'
			},
			panoscene: {
				type: String,
				default: 'scene_ct'
			}
		},
		watch:{
			scene: function(val){
				console.log(val)
				this.showScene(val)
			}
		},
    mounted(){
			const { embedpano } = window
			embedpano({xml:"/vtour/"+this.scene+".xml", target:"pano", html5:"always", mobilescale:1.0, passQueryParameters:true, onready : this.krpano_onready_callback});
    },
    methods:{
			panoChanged(){
				console.log('panoChanged')
				this.scenelist = []
				const count = window.krpano.get('scene.count')
				for (let i = 0; i < count; i++) {
					const name = window.krpano.get(`scene[${i}].name`)
					const title = window.krpano.get(`scene[${i}].title`)
					const thumburl = window.krpano.get(`scene[${i}].thumburl`)
					this.scenelist.push({ name, title, thumburl })
				}
			},
			showScene(id){
				this.scene = id
				this.loadpano('/vtour/'+id+'.xml')
			},
			krpano_onready_callback(krpano_interface){
				window.krpano = krpano_interface
				window.krpano.hooks = this.hooks
			},
			loadpano(xmlname){
				if (window.krpano){
					window.krpano.call("removelayer('map',true)")
					window.krpano.call("loadpanoscene(" + xmlname + ",'"+this.panoscene+"', null, MERGE, BLEND(0.5));");
				}
			},
			loadScene(scene){
				if (window.krpano){
					this.panoscene = scene
					window.krpano.call("loadscene('"+scene+"', null, MERGE, BLEND(0.5));");
				}
			},
			toggleMenu(){
				console.log('toggleMenu')
				if(this.closepmenu){
					this.closepmenu = false
				}else{
					this.closepmenu = true
				}
			},
		},
		beforeDestroy () {
			const { removepano } = window
			if (window.krpano) {
				removepano(window.krpano.id)
				console.log('removepano')
				delete window.krpano
			}
		}
}
</script>
<style lang="scss">
.vr-panel{
	width: 100%;
	height: 100%;
	#pano{
			width: 100%;
			height: 100%;

		div{
			box-sizing: content-box;
		}
	}

	.logo{
		position: absolute;
		// @include bg('logo.png',164px,28px);
	}

	.panomenu{
		position: absolute;
		bottom: 120px;
		left: 0;
		display: flex;
		align-items: center;
		background: rgba(0,0,0,0.8);
		padding: 5px;
		border-radius: 0 40px 40px 0;
		transform: translateX(0);
		transition: all .5s ease;
		z-index: 88;

		&.close{
			transform: translateX(-270px);
			.btn{
				.iconfont{
					display: inline-block;
					transform: rotate(180deg);
				}
			}
		}

		.scroll{
			max-width: 260px;
			overflow-x: scroll;
			overflow-y: hidden;
			-webkit-overflow-scrolling:touch;
			touch-action: pan-x;

			.thumbs{
				position: relative;
				// display: inline-block;
				white-space: nowrap;

				&.jg{
					width: 370px;
				}
				
				.item{
					position: relative;
					// float: left;
					display: inline-block;
					width: 70px;
					margin: 0 2px;
					opacity: .5;
					border: 3px solid #fff;

					&.active{
						opacity: 1;
					}

					img{
						display: block;
						width: 100%;
						height: 50px;
						object-fit: cover;
					}

					span{
						position: absolute;
						width: 100%;
						bottom: 0;
						left: 0;
						background: rgba(0,0,0,0.8);
						color: #fff;
						// margin: 2px;
						text-align: center;
						padding: 2px 0;
						font-size: 10px;
					}
				}
			}
		}

		.btn{
			color: #fff;
			padding: 10px 10px 10px 15px;
			.iconfont{
				font-size: 16px;
			}
		}
	}

}
</style>

