<template>
  <div class="house-page">
		<div class="submenu" :class="{ close: openMenu==false }">
			<a class="item" :class="{ active : house== 'b1' }" @click="showHouse('b1')" href="javascript:;">峯·著</a>
			<a class="item" :class="{ active : house== 'c1' }" @click="showHouse('c1')" href="javascript:;">峯·域</a>
			<a class="item" :class="{ active : house== 'd1' }" @click="showHouse('d1')" href="javascript:;">峯·境</a>
			<div class="box"></div>
		</div>
		<div class="rightmenu">
			<a class="item" :class="{ active : type== 'pic' }" @click="changeType('pic')" href="javascript:;">户型图</a>
			<a class="item" :class="{ active : type== '3d' }" @click="changeType('3d')" href="javascript:;">3D户型</a>
			<a class="item" :class="{ active : type== 'vr' }" @click="changeType('vr')" href="javascript:;">VR户型</a>
		</div>
		<three v-if="type=='3d'" :house='house'></three>
		<div class="pic" v-if="type=='pic'">
			<swiper class="swiper vertical" :options="swiperOptionv" ref="swiperv">
				<swiper-slide>
					<swiper class="swiper swiper1" :options="swiperOptionh1" ref="swiperh1">
						<swiper-slide>
							<div class="b1">
								<div class="img">
									<img src="../assets/images/house/b1v.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="c1">
								<div class="img">
									<img src="../assets/images/house/c1v.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="d1">
								<div class="img">
									<img src="../assets/images/house/d1v.png">
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</swiper-slide>
				<swiper-slide>
					<swiper class="swiper swiper2" :options="swiperOptionh2" ref="swiperh2">
						<swiper-slide>
							<div class="b1">
								<div class="img">
									<img src="../assets/images/house/b1.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="c1">
								<div class="img">
									<img src="../assets/images/house/c1.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="d1">
								<div class="img">
									<img src="../assets/images/house/d1.png">
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</swiper-slide>
			</swiper>
			<div class="guide" v-if="guide" @click="hideGuide"></div>
		</div>
		<vr v-if="type=='vr'" :scene="scene" :panoscene="panoscene"></vr>
		<div class="soon-page" v-if="soon">
        <div class="center">Coming Soon...</div>
    </div>
	</div>
</template>
<script>
import { mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper.scss'
import Three from '@/components/Three'
import Vr from '@/components/VR'

export default {
	name: "House",
	components:{
		Swiper,
		SwiperSlide,
		Three,
		Vr
	},
  data() {
    return {
			house: 'b1',
			type: 'pic',
			guide: false,
			soon: false,
			scene: '150',
			panoscene: 'scene_ct',
			swiperOptionv:{
				direction: 'vertical'
			},
			swiperOptionh1: {
				on: {
					slideChange: (swiper) => {
						if(swiper.activeIndex == 0) {
							this.house = 'b1'
							this.$refs.swiperh2.$swiper.slideTo(0, 0)
						}
						if(swiper.activeIndex == 1) {
							this.house = 'c1'
							this.$refs.swiperh2.$swiper.slideTo(1, 0)
						}
						if(swiper.activeIndex == 2) {
							this.house = 'd1'
							this.$refs.swiperh2.$swiper.slideTo(2, 0)
						}
					}
				}
			},
			swiperOptionh2: {
				on: {
					slideChange: (swiper) => {
						if(swiper.activeIndex == 0) {
							this.house = 'b1'
							this.$refs.swiperh1.$swiper.slideTo(0, 0)
						}
						if(swiper.activeIndex == 1) {
							this.house = 'c1'
							this.$refs.swiperh1.$swiper.slideTo(1, 0)
						}
						if(swiper.activeIndex == 2) {
							this.house = 'd1'
							this.$refs.swiperh1.$swiper.slideTo(2, 0)
						}
					}
				}
			}
    }
	},
	computed: mapState([
		'openMenu'
	]),
	mounted(){
    this.guide = localStorage.getItem('house_pic_guide') ? false : true
		if(this.$route.params.house != undefined){
			this.showHouse(this.$route.params.house)
			this.changeType(this.$route.params.type)
		}
  },
  methods: {
		hideGuide(){
      this.guide = false
      localStorage.setItem('house_pic_guide',true)
    },
		showHouse(id){
			this.house = id
			if(this.type == 'pic'){
				let index = 0
				if(id == 'c1') index = 1
				if(id == 'd1') index = 2
				this.$refs.swiperh1.$swiper.slideTo(index, 300)
				this.$refs.swiperh2.$swiper.slideTo(index, 300)
			}
			if(this.type == 'vr'){
				console.log('vr',this.house)
				this.soon = false
				if(id == 'b1') {
					// this.$router.push('soon')
					this.soon = true
				}
				if(id == 'c1') {
					this.scene = '150'
					this.panoscene = 'scene_ct'
				}
				if(id == 'd1') {
					this.scene = '180'
					this.panoscene = 'scene_xg'
				}
			}
		},
		changeType(id){
			this.type = id
			if(id == 'pic'){
				let index = 0
				if(this.house == 'c1') index = 1
				if(this.house == 'd1') index = 2
				setTimeout(()=>{
					// this.$refs.swiperv.$swiper.slideTo(0, 0)
					this.$refs.swiperh1.$swiper.slideTo(index, 0)
					this.$refs.swiperh2.$swiper.slideTo(index, 0)
				},50)
			}
			if(id == 'vr'){
				console.log('vr',this.house)
				this.soon = false
				if(this.house == 'b1') {
					// this.$router.push('soon')
					this.soon = true
				}
				if(this.house == 'c1') {
					this.scene = '150'
					this.panoscene = 'scene_ct'
				}
				if(this.house == 'd1') {
					this.scene = '180'
					this.panoscene = 'scene_xg'
				}
			}
		},
	}
};
</script>
<style lang="scss">
@import "@/assets/styles/common.scss";

.house-page{
	width: 100%;
	height: 100%;
	#house{
		width: 100%;
		height: 100%;
	}
	.rightmenu{
		position: absolute;
		top: 25vh;
		left: 0;
		z-index: 99;

		.item{
			display: block;
			border:1px solid #fff;
			border-left: 0;
			border-radius: 0 12px 12px 0;
			margin-bottom: 5px;
			color: #fff;
			padding: 5px 15px 5px 12px;
			background-color: rgba(0,0,0,0.6);

			&.active{
				background-color: rgba(164, 134, 104, .9)
			}
		}
	}

	.pic{
		height: 100vh;
		overflow: hidden;

		.guide{
			position: fixed;
			top: 0;
			left: 0;
			// width: 100vw;
			// height: 100vh;
			@include bg('tips2.png',100vw,100vh);
			background-size: 347px 255px;
			z-index: 77;
		}

		.vertical,.swiper1,.swiper2{
			height: 100vh;
		}

		.swiper1{
			@include bg('house/bg.png',100%,100%,center 10vh);
			background-size: 375px 453px;
		}
		.swiper2{
			@include bg('house/bg2.png',100%,100%,top center);
			background-color: #fff;
		}
		
		.b1,.c1,.d1{
			height: 100vh;

			.img{
				position: relative;
				padding-top: 10vh;

				img{
					width: 100%;
					// height: 453px;
					min-height:100%;
				}
			}
		}
	}
}
.soon-page{
        position: absolute;
				top: 0;
				left: 0;
        width: 100%;
        height: 100vh;
				background: #141F2A;
				z-index: 90;

        .center{
            margin-top: 40vh;
            text-align: center;
            font-size: 16px;
            color: #fff;
            line-height: 1.5;
        }
    }
@media screen and (max-height: 603px) {
    .house-page{
        .rightmenu{
			top: 27vh;
        }
    }
}
</style>
